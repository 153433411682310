

	import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

	import { ProjectStatus } from '@/modules/projects/types/entities';
	import { PropType } from 'vue';
	import { ProjectStatusMixin } from '@/mixins/project-status-mixin.component';

	@Component({
		mixins: [ProjectStatusMixin]
	})
	export default class ProjectStatusSelectComponent extends Vue {
		@PropSync('value', { type: Object as PropType<ProjectStatus> }) valueSync!: ProjectStatus;
		@Prop() labelIcon!: string;
		@Prop() labelText!: string;
		@Prop() statuses!: ProjectStatus[];
		@Prop() placeholderText!: string;
	}
